import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ServicePage from "../components/ServicePage/ServicePage"

const services = () => {
  return (
    <Layout>
      <Seo title="Services" />
      <ServicePage />
    </Layout>
  )
}

export default services
