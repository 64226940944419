import React from "react"
import * as styles from "./styles.module.scss"
import Container from "../Container/Container"
import { Link } from "gatsby"

//Icons
import Contruction from "../../images/Icons/Contruction.svg"
import Logistics from "../../images/Icons/Logistics.svg"
import Traders from "../../images/Icons/Traders.svg"

const ServicePage = () => {
  return (
    <section className={styles.serviceSection}>
      <Container>
        <div className={styles.serviceHeading}>
          <h3 className={styles.serviceHeadingSub}>Our Capabalities</h3>
          <h1 className={styles.serviceHeadingMain}>
            We are Specialized in the Following <span>Services</span>
          </h1>
        </div>
      </Container>
      <div className={styles.services}>
        <div className={styles.serviceOptionYellow}>
          <img
            src={Contruction}
            className={styles.serviceImage}
            alt="Contruction"
          ></img>
          <h1 className={styles.serviceOptionHeading}>Construction</h1>
          <h3 className={styles.serviceOptionHeadingSub}>
            Expertise in Commercial and Residential projects
          </h3>
          <Link
            to="/services/construction"
            className={styles.serviceOptionYellowBtn}
          >
            Check Out
          </Link>
        </div>
        <div className={styles.serviceOptionBlue}>
          <img
            src={Logistics}
            className={styles.serviceImage}
            alt="Logistics"
          ></img>
          <h1 className={styles.serviceOptionHeading}>Logistics</h1>
          <h3 className={styles.serviceOptionHeadingSub}>
            Best in-class Logistics system with GPS tracking
          </h3>
          <Link
            to="/services/logistics"
            className={styles.serviceOptionBlueBtn}
          >
            Check Out
          </Link>
        </div>
        <div className={styles.serviceOptionRed}>
          <img
            src={Traders}
            className={styles.serviceImage}
            alt="Traders"
          ></img>
          <h1 className={styles.serviceOptionHeading}>Traders</h1>
          <h3 className={styles.serviceOptionHeadingSub}>
            Granite, Marbles, Tiles and Sanitarywares
          </h3>
          <Link to="/services/traders" className={styles.serviceOptionRedBtn}>
            Check Out
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ServicePage
