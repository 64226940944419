// extracted by mini-css-extract-plugin
export var serviceHeading = "styles-module--serviceHeading--bba7a";
export var serviceHeadingMain = "styles-module--serviceHeadingMain--d153c";
export var serviceHeadingSub = "styles-module--serviceHeadingSub--d642c";
export var serviceImage = "styles-module--serviceImage--616be";
export var serviceOptionBlue = "styles-module--serviceOptionBlue--d5ba8";
export var serviceOptionBlueBtn = "styles-module--serviceOptionBlueBtn--ad140";
export var serviceOptionHeading = "styles-module--serviceOptionHeading--8ab75";
export var serviceOptionHeadingSub = "styles-module--serviceOptionHeadingSub--9179b";
export var serviceOptionRed = "styles-module--serviceOptionRed--85a89";
export var serviceOptionRedBtn = "styles-module--serviceOptionRedBtn--db639";
export var serviceOptionYellow = "styles-module--serviceOptionYellow--7cb01";
export var serviceOptionYellowBtn = "styles-module--serviceOptionYellowBtn--4889c";
export var serviceSection = "styles-module--serviceSection--0cf68";
export var services = "styles-module--services--bc597";